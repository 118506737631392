/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
const enum TranslationKeyPrefix {
  NEW = "new",
  SIDEBAR_MATERIALS = "materials",

  SIDEBAR_TASKS = "tasks",
  SIDEBAR_COMMON = "common",
  SIDEBAR_HEADLINE = "headline",
  SIDEBAR_SECTIONS = "sections",
  SIDEBAR_COURSES = "courses",

  TASKS_SUMMARY = "summary",
  TASKS_HEADER = "header",
  PLUGIN_ERROR = "error",
  TASKS_TOOLBAR = "toolbar",
  TASKS_STUB = "stub",
  HEADER_MENU = "menu",
  HEADER_MOBILE = "mobile",
  HEADER_NOTIFICATIONS = "notifications",

  COURSE_STEPS = "steps",
  COURSE_HEADLINE = "headline",
  COURSE_TASK_MODULES = "task_modules",
  COURSE_FOOTER = "footer",
  COURSE_CERTIFICATE = "certificate",
  COURSE_COHORTS = "cohorts",

  PAID_COURSE_HEADLINE = "headline",
  PAID_COURSE_PROGRAM = "program",
  PAID_COURSE_VIDEOS = "new_videos",
  PAID_COURSE_ADDITIONAL = "additional_blocks",

  COURSES_FILTERS = "filters",

  VIDEOS_HEADER = "header",
  VIDEOS_DISPLAY = "header.display",
  VIDEOS_VIDEO = "video",
  VIDEOS_SORT = "sort",
  VIDEOS_PLAYLISTS = "playlists",

  VIDEO_MENU = "menu",
  VIDEO_HEADLINE = "headline",

  SELECT_COHORT_BUTTONS = "buttons",
  SELECT_COHORT_PROGRAM = "program",
  SELECT_COHORT_SCHEDULE = "schedule",

  PAYMENT_CHIPS = "chips",
  PAYMENT_PRICE = "price",
  PAYMENT_TABS = "tabs",
  PAYMENT_METHODS = "methods",
  PAYMENT_BUNDLE = "bundle_courses",
  PAYMENT_PROMOCODE = "promocode",
  PAYMENT_TOTAL = "total",
  PAYMENT_TINKOFF_ERROR = "tinkoff_error",

  ACCOUNT_COMMON = "common",
  ACCOUNT_MAIN = "main",
  ACCOUNT_TIMEZONE = "timezone",
  ACCOUNT_PASSWORD = "password",
  ACCOUNT_EMAIL_VERIFICATION = "email_verification",
  ACCOUNT_CERTIFICATE_NOTIFICATION = "certificate_notification",

  TEST_RESULT = "result",

  MODAL_KASPI = "kaspi",
  MODAL_CHANGE_EMAIL = "change_email",
  MODAL_CHANGE_PHONE = "change_phone",
  MODAL_SUBMIT_BID = "submit_bid",
  MODAL_REPORT_MISTAKE = "report_mistake",
  MODAL_ASK_QUESTION = "ask_question",

  ERROR_404 = "404",
  ERROR_NETWORK = "network",
  ERROR_UNEXPECTED = "unexpected",
  ERROR_STACK = "stack",

  LIVE_CLASSES_CALENDAR = "calendar",
  LIVE_CLASSES_UPCOMING = "upcoming",
  LIVE_CLASSES_ALL = "all",
  LIVE_CLASSES_SORT = "sort",
  LIVE_CLASSES_ITEM = "item",

  CONVERSATION_TABLE = "table",
  CONVERSATION_CHECKS = "checks",
  CONVERSATION_CHATS = "chats",
  CONVERSATION_HEADLINE = "headline",
  CONVERSATION_READ_ALL = "read_all",
  CONVERSATION_SECTIONS = "sections",
  CONVERSATION_STATUSES = "statuses",
  CONVERSATION_TYPES = "types",
  CONVERSATION_DATE_RANGE = "date_range",

  PARAMETERS_COPY = "copy",

  FORM_SERVICE = "_service",

  MODAL_SERVICE = "_service",
}

export default TranslationKeyPrefix;
