import { z as zod } from "zod";

export function successfulResponse<T extends zod.ZodTypeAny>(data: T) {
  return zod
    .object({
      success: zod.literal(true),
      data,
    })
    .transform(({ data }) => data as zod.infer<T>);
}
