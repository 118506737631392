import { z as zod } from "zod";

const certificateLanguagesSchema = zod.union([
  zod.literal("ru"),
  zod.literal("en"),
]);

export const accountCertificateSchema = zod.object({
  id: zod.number(),
  uuid: zod.string(),
  course: zod.object({
    id: zod.number(),
    name: zod.string(),
    url: zod.string(),
  }),
  link: zod.record(certificateLanguagesSchema, zod.string().nullable()),
  preview: zod.record(certificateLanguagesSchema, zod.string().nullable()),
});

export type AccountCertificate = zod.infer<typeof accountCertificateSchema>;

export const accountCertificatesArraySchema = zod.array(
  accountCertificateSchema
);
