import { generatePath, redirect } from "react-router-dom";

import Paths from "@/constants/paths";
import NotFoundError from "@/errors/not-found/NotFoundError";
import useAuthStore from "@/stores/auth";
import { parseErrorStatus } from "@/utils/fetch/parseErrorResponse";

import { urlWithCurrentSearch } from "../url";

export function tryHandleUnauthorized(error: unknown) {
  if (parseErrorStatus(error) === 401) {
    useAuthStore.getState().logout();
    throw redirect(urlWithCurrentSearch(Paths.LOGIN, window.location.href));
  }
}

export function tryHandleCourseNotFound(message: string | undefined) {
  if (message === "COURSE_NOT_FOUND") {
    throw new NotFoundError();
  }
}

export function tryHandleTestNotPassed(
  course: string,
  message: string | undefined
) {
  if (message === "TEST_NOT_PASSED" || message === "Entrance test not passed") {
    throw redirect(
      generatePath(Paths.COURSE_TEST, {
        course,
      })
    );
  }
}
