import { create } from "zustand";

export interface RedirectUrlState {
  url: string | null;

  save: (url: string) => void;
  clear: () => void;
  pop: () => string | null;
}

const useRedirectUrlStore = create<RedirectUrlState>((set, get) => ({
  url: null,

  save: (url) => set({ url }),
  clear: () => set({ url: null }),
  pop: () => {
    const { url, clear } = get();
    clear();
    return url;
  },
}));

export default useRedirectUrlStore;
